import React, { useState } from "react";
import serviceWorldNewCaseStyle from "./serviceWorldNewCaseStyle";
import ServiceWorldContentBox from "./ServiceWorldContentBox";
import IconDocument from "../../assets/icons/generic/IconDocument";
import { Box, Divider, Grid, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { getProductData, productCategoryData } from "../../services/productService";
import DialogModal from "../DialogModal/DialogModal";
import { InfoOutlined } from "@mui/icons-material";
import IconWrapper from "../IconWrapper/IconWrapper";

const ServiceWorldNewCase = () => {
  const [legalExpenseInsuranceModalOpen, setLegalExpenseInsuranceModalOpen] = useState(false);
  return (
    <Box sx={serviceWorldNewCaseStyle.newCaseContainer}>
      <ServiceWorldContentBox headline={"Neuen Fall anlegen"} icon={IconDocument}>
        <List sx={serviceWorldNewCaseStyle.bottomPadding}>
          <ListItem sx={serviceWorldNewCaseStyle.productCategoryHeadline}>Familienrecht</ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productItem} component={Link} to={"/servicewelt/familienrecht/scheidung"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("divorce", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Scheidung"} />
              </Grid>
              <Grid item>Scheidung</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={serviceWorldNewCaseStyle.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("familyLawCase", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Erstberatung"} />
              </Grid>
              <Grid item>Erstberatung Familienrecht</Grid>
              <Grid item sx={serviceWorldNewCaseStyle.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={serviceWorldNewCaseStyle.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("alimony", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Unterhalt"} />
              </Grid>
              <Grid item>Erstberatung Unterhalt</Grid>
              <Grid item sx={serviceWorldNewCaseStyle.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={serviceWorldNewCaseStyle.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("death", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Erbrecht"} />
              </Grid>
              <Grid item>Erstberatung Erbrecht/Todesfall</Grid>
              <Grid item sx={serviceWorldNewCaseStyle.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productCategoryHeadline}>Verkehrsrecht</ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productItem} component={Link} to={"/servicewelt/verkehrsrecht/ordnungswidrigkeit"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("traffic", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Verkehr"} />
              </Grid>
              <Grid item>Ordnungswidrigkeit</Grid>
            </Grid>
          </ListItem>
          {/*<Divider />
          <ListItem sx={serviceWorldNewCaseStyle.productItem} component={Link} to={"/servicewelt/verkehrsrecht/verkehrsunfall"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("trafficAccident", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Verkehrsunfall"} />
              </Grid>
              <Grid item>Verkehrsunfall</Grid>
            </Grid>
          </ListItem>*/}
          <ListItem sx={serviceWorldNewCaseStyle.productCategoryHeadline}>Arbeitsrecht</ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productItem} component={Link} to={"/servicewelt/arbeitsrecht/kuendigung"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("settlement", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Abfindung"} />
              </Grid>
              <Grid item>Kündigungsschutzklage</Grid>
            </Grid>
          </ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productCategoryHeadline}>Vertragsrecht</ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={productCategoryData.contractLaw.icon} sx={serviceWorldNewCaseStyle.icon} alt={"Vertragsrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Vertragsrecht</Grid>
              <Grid item sx={serviceWorldNewCaseStyle.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem sx={serviceWorldNewCaseStyle.productItem} component={Link} to={"/lp/wbs/schufa"}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={getProductData("dataLeakContract", "productIcon")} sx={serviceWorldNewCaseStyle.icon} alt={"Datenweitergabe"} />
              </Grid>
              <Grid item sx={serviceWorldNewCaseStyle.overwriteLinkColor}>
                Illegale Datenweitergabe
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productCategoryHeadline}>Mietrecht</ListItem>
          <ListItem sx={serviceWorldNewCaseStyle.productItem} onClick={() => setLegalExpenseInsuranceModalOpen(true)}>
            <Grid container alignItems={"center"} spacing={3} wrap={"nowrap"}>
              <Grid item>
                <IconWrapper icon={productCategoryData.tenancyLaw.icon} sx={serviceWorldNewCaseStyle.icon} alt={"Mietrecht"} />
              </Grid>
              <Grid item>Beratung / Vertretung Mietrecht</Grid>
              <Grid item sx={serviceWorldNewCaseStyle.infoIcon}>
                <InfoOutlined />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </ServiceWorldContentBox>
      <DialogModal
        open={legalExpenseInsuranceModalOpen}
        onClose={() => setLegalExpenseInsuranceModalOpen(false)}
        title={"Prüfung Ihrer Rechtschutzversicherung erforderlich"}
      >
        <Box sx={serviceWorldNewCaseStyle.modalContent}>
          Dieser Service kann erst genutzt werden, wenn bereits eine <strong>Deckungszusage Ihrer Rechtsschutzversicherung</strong> vorliegt. Aktuell
          unterstützen wir in diesem Rechtsbereich nur bestimmte Versicherungspartner. Schreiben Sie uns unter{" "}
          <a href={"mailto:service@legalbird.de"}>service@legalbird.de</a>, und wir klären, ob Ihre Versicherung dazu gehört!
        </Box>
      </DialogModal>
    </Box>
  );
};

export default ServiceWorldNewCase;
